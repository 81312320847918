<template>
  <Carousel/>
</template>

<script>
import Carousel from '../components/carousel/Carousel.vue'
export default {
    name: "CarouselView",
    components: { Carousel },
    title: "Carrusel | Baja California Health Tourism"
}
</script>

<style>

</style>