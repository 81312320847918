<template>
    <v-container>
        <v-toolbar flat class="align-center mb-2">
            <v-row class="gap-custom">
                <v-col cols="9">
                    <v-toolbar-title>
                        Carrusel de im&aacute;genes
                    </v-toolbar-title>
                </v-col>
                <v-col cols="1">
                    <v-btn color="primary" :disabled="saveChanges || loading"
                        @click="dialogCreate = true;">A&ntilde;adir</v-btn>
                </v-col>
                <v-col cols="1">
                    <v-btn color="success" @click="changePosition" :disabled="saveChanges || loading">Guardar</v-btn>
                </v-col>
            </v-row>
            <v-dialog v-model="dialogCreate" max-width="1000px" persistent>
                <v-card>
                    <v-form ref="form" v-model="valid" lazy-validation>
                        <v-card-title class="text-h6 grey lighten-2">
                            <span class="headline">A&ntilde;adir imagen</span>
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-text>
                            <div class="container mt-0 pt-0">
                                <v-file-input v-model="file" small-chips accept="image/png, image/jpeg, image/bmp"
                                    :rules="[v=>!!v || 'Campo requerido']" show-size clearable label="Agregar ficheros">
                                    <template v-slot:selection="{ index, text }">
                                        <v-chip small text-color="white" color="#295671" close
                                            @click:close="removeChip(index)">
                                            {{ text }}
                                        </v-chip>
                                    </template>
                                </v-file-input>
                            </div>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" :disabled="saveChanges" text @click="close">
                                Cancelar
                            </v-btn>
                            <v-btn :disabled="!valid || saveChanges" color="primary" text @click="saveItem">
                                Guardar
                            </v-btn>
                        </v-card-actions>
                    </v-form>
                </v-card>
            </v-dialog>
        </v-toolbar>
        <v-row>
            <v-col cols="6" lg="6" md="6" sm="12">
                <v-card>
                    <v-card-title>Habilitadas</v-card-title>
                    <v-card-text>
                        <vue-draggable :disabled="loading"
                            class="d-flex flex-col gap-2 p-4 w-300px h-300px m-auto bg-gray-500/5 rounded overflow-auto"
                            v-model="dataView" animation="150" ghostClass="ghost" group="people">
                            <div class="d-flex" v-for="item in dataView" :key="item.id">
                                <v-btn class="mx-2 btn-delete-custom" fab dark small color="error"
                                    @click="openDelete(item)">
                                    <v-icon>mdi-delete</v-icon>
                                </v-btn>
                                <v-img width="100%" height="150" class="cursor-move h-30 bg-gray-500/5 rounded mb-3"
                                    :src="item.url_image" :lazy-src="item.url_image" />
                            </div>
                        </vue-draggable>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="6" lg="6" md="6" sm="12">
                <v-card>
                    <v-card-title>Ocultas</v-card-title>
                    <v-card-text>
                        <vue-draggable :disabled="loading"
                            class="d-flex flex-col gap-2 p-4 w-300px h-300px m-auto bg-gray-500/5 rounded overflow-auto"
                            v-model="dataDisable" animation="150" group="people" ghostClass="ghost">
                            <div class="d-flex" v-for="item in dataDisable" :key="item.id">
                                <v-btn class="mx-2 btn-delete-custom" fab dark small color="error"
                                    @click="openDelete(item)">
                                    <v-icon>mdi-delete</v-icon>
                                </v-btn>
                                <v-img width="100%" height="150" class="cursor-move h-30 bg-gray-500/5 rounded mb-3"
                                    :src="item.url_image" :lazy-src="item.url_image" />
                            </div>
                        </vue-draggable>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-dialog v-model="dialogDelete" persistent max-width="350">
            <v-card>
                <v-card-text>¿Est&aacute;s seguro que quieres eliminar esta imagen?</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" :disabled="saveChanges" @click="closeDelete">
                        Cancelar
                    </v-btn>
                    <v-btn color="error" :disabled="saveChanges" @click="deleteAction">
                        Eliminar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import carouselServices from '@/providers/CarouselService';

export default {
    name: "Carousel",
    data(){
        return {
            dataView: [],
            dataDisable: [],
            loading: true,
            valid: false,
            saveChanges: false,
            dialogCreate: false,
            file: null,
            deleteItem: null,
            dialogDelete: false,
        }
    },
    mounted(){
        this.loadData();
    },
    methods: {
        openDelete(item){
            this.deleteItem = item;
            this.dialogDelete = true;
        },

        closeDelete(){
            this.deleteItem = null;
            this.dialogDelete = false;
        },

        close(){
            this.dialogCreate = false;
            this.file = null;
            this.valid = false;
        },
        loadData(){
            this.loading = true;
            carouselServices.getAllCarousel().then(resp=>{
                this.dataView = resp.value.filter(e=>e.order!=null);
                this.dataDisable = resp.value.filter(e=>e.order==null);
            }).catch(err=>console.log(err)).finally(()=>{
                this.loading = false;
            });
        },
        saveItem(){
            this.saveChanges = true;
            carouselServices.add(this.file).then(()=>{
                this.loadData();
                this.close();
            }).catch(err=>console.log(err)).finally(()=>{
                this.saveChanges = false;
            });
        },
        deleteAction(){
            this.saveChanges = true;
            carouselServices.delete(this.deleteItem.id).then(()=>{
                this.closeDelete();
                this.loadData();
            }).catch(err=>console.log(err)).finally(()=>{
                this.saveChanges = false;
            });
        },
        changePosition(){
            this.saveChanges = true;
            carouselServices.updatePosition(this.dataView.map(e=>e.id)).then(()=>{
                this.loadData();
            }).catch(err=>console.log(err)).finally(()=>{
                this.saveChanges = false;
            });
        }
    }
}
</script>

<style>
.btn-delete-custom {
    position: absolute;
    z-index: 100;
    right: 15px;
    margin-top: 0.3rem;
}

.ghost {
    opacity: 0.5;
    background: #c8ebfb;
}

.w-300px {
    min-width: 300px;
} 

.h-300px {
    min-height: 300px;
    max-height: 1000px;
}

.m-auto{
    margin: auto;
}

.overflow-auto{
    overflow: auto;
}

.flex-col {
    flex-direction: column;
}

.gap-custom{
    gap: 1rem;
}
</style>